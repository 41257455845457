<template>
  <div>
    <p
      v-if="type === 'paragrath'"
      :style="`height:${height}px;width:${width}%`"
      :class="['upper white btn', classe]"
    >
      {{ label }}
    </p>

    <button
      v-else
      :disabled="isDisable"
      :style="`height:${height}px;width:${width}%`"
      :class="['upper white btn', classe]"
      type="submit"
    >
      {{ label }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      defaul: '',
      required: true
    },
    type: {
      type: String,
      defaul: ''
    },
    classe: {
      defaul: '',
      type: String
    },
    height: {
      type: Number,
      default: 50
    },
    isDisable: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 100
    }
  }
}
</script>
<style lang="sass" scoped>
p
  align-items: center
  display: inline-flex
  justify-content: center
.btn
  background-color: #2e7eed
  border-radius: 8px
  border: none
  padding: 0
  &:hover
    background-color: #005bda
    color: white !important
</style>
