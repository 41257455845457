<template>
  <div class="col-md-5 mb-3 mb-md-0">
    <div class="p-4 box-why">
      <h1 class="title bold bold-xs-none mb-3 mb-md-4 text-center">
        Por que escolher nossa plataforma?
      </h1>

      <div class="items">
        <div
          class="bordered-bottom d-inline-flex align-items-center box-reason py-3"
          v-for="(reason, indReason) in reasons"
          :key="`reason-${indReason}`"
        >
          <div class="px-0 col-auto">
            <component :is="icons[reason.icon]" />
          </div>

          <div class="px-0 col">
            <div class="d-grid px-4">
              <div class="column px-0 mb-2">
                <h6>{{ reason.title }}</h6>
              </div>

              <div class="column px-0">
                <p class="secondary">
                  {{ reason.description }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CertificateIcon from '@/assets/icons/Certificate'
import DirectionGraphIcon from '@/assets/icons/DirectionGraph'
import GiftIcon from '@/assets/icons/Gift'
import Slider from '@/components/shared/Slider'

export default {
  components: {
    Slider
  },

  data () {
    return {
      icons: {
        CERTIFICATE: CertificateIcon,
        DIRECTION_GRAPH: DirectionGraphIcon,
        PRESENTE: GiftIcon
      },

      reasons: [
        {
          icon: 'CERTIFICATE',
          title: 'Aprenda com os melhores',
          description: 'Estude com profissionais capacitados e experientes em cada área de atuação.'
        }, {
          icon: 'DIRECTION_GRAPH',
          title: 'Direção',
          description: 'Encontre a trilha de conhecimento ideal para atender suas necessidades profissionais.'
        }
      ]
    }
  },
  computed: {
    slidesToShow () {
      return this.$store.getters.getWidth < 500 ? 2 : 4
    }
  }
}
</script>
<style lang="sass" scoped>
.items
  display: grid
  height: calc(100% - 100px)
p
  font-size: 15px
  line-height: 20px
  color: #777 !important
.title
  font-size: 24px
  color: #444 !important
.box-why
  background-color: white
  box-shadow: 0 7px 20px 0 rgba(0,0,0,.08)
  border-radius: 5px
img
  max-width: 100%
.box-reason
  border-bottom: 1px solid #ddd
h6
  font-size: 16px
  color: #444
  font-weight: bold

@media (max-width: 500px)
  p
    font-size: 14px
</style>
