<template>
  <header id="home">
    <div class="container-cursos">
      <div class="row justify-content-between align-items-center">
        <div class="col-md-2 col-4">
          <a
            :href="$linkAssomasul"
            target="_blank"
          >
            <img
              alt="Logo marca"
              class="logo py-2 py-md-0"
              src="@/assets/images/logo.png"
            >
          </a>
        </div>

        <div class="col-md-6 col-8">
          <div class="row align-items-center justify-content-end mx-0 text-end">
            <div class="col-md-auto col-12 px-0 px-md-2">
              <a
                class="btn py-1 px-0 mt-md-1 upper primary-color hovered"
                :href="$linkPlataforma"
              >
                <img
                  src="@/assets/images/user.png"
                  class="icon user-icon me-md-1 me-0"
                >
                Já possuo cadastro
              </a>
            </div>

            <div class="col-md-auto col-12 px-0 px-md-2 d-none d-md-block">
              <a
                data-toggle="modal"
                data-target="#modal-login"
                id="header-modal-cadastro"
                class="btn btn-landing py-1 px-0 mt-md-1 upper primary-color px-2"
              >
                <img
                  src="@/assets/images/arrow-right.png"
                  class="icon arrow-icon me-md-1 me-0"
                >
                Faça seu cadastro
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: 'HeaderComp'
}
</script>
<style lang="sass" scoped>
.btn-landing
  outline: none !important
  border-radius: 10px !important
  box-shadow: none !important
  border: 1px solid #2e7eed !important
  background-color: #2e7eed
  padding: 5px 20px
  color: #fff
  &:hover
    color: #fff !important

header
  background-color: rgb(236, 236, 236)
  .icon
    filter: grayscale(1) brightness(0)
    width: 18px
    height: 18px
  .btn
    font-weight: 500
    font-size: 13px
.white:hover
  color: white
.logo
  width: 260px

.user-icon
  margin-top: -4px
  width: 22px
  height: 22px

.arrow-icon
  margin-top: -5px
  width: 16px !important
  height: 16px !important
  filter: grayscale(1) brightness(10) !important

@media (max-width: "500px")
  .btn
    font-size: 12px

  .logo
    margin-bottom: 3px
    max-width: 100px !important

  .user-icon
    margin-top: -5px
    width: 16px !important
    height: 16px !important

  .arrow-icon
    width: 16px !important
    height: 16px !important

</style>
