<template>
  <label class="content secondary column">
    <div class="align-items-baseline">
      <span class="form-label text-start w-100 font-14 pointer user-select-none mb-0">{{ label }}</span>
      <input
        type="checkbox"
        v-model="input"
      >
      <span class="checkmark" />
    </div>
  </label>
</template>
<script>
export default {
  props: {
    value: {},
    label: {
      type: String
    },
    name: {
      type: String,
      default: ''
    },
    blur: {
      type: Function,
      default: () => {}
    }
  },

  data () {
    return {
      input: this.value ? 1 : 0
    }
  },

  watch: {
    input: {
      handler (input) {
        if (this.value === input) return
        this.$emit('input', input ? 1 : 0)
        this.blur({ value: input ? 1 : 0, name: this.name })
      }
    },

    value: {
      handler (value) {
        if (value === this.input) return
        this.input = value ? 1 : 0
      }
    }
  }
}
</script>
<style lang="sass" scoped>
.content
  position: relative
  padding-left: 35px
  cursor: pointer
  font-size: 14px
  user-select: none
  .label
    margin-top: 10px
  input
    position: absolute
    opacity: 0
    cursor: pointer
    height: 0
    width: 0
  .checkmark
    position: absolute
    left: 0
    height: 20px
    border-radius: 3px
    border: 1px solid #ced4da
    width: 20px
  input:checked ~ .checkmark:after
    display: block
  .form-label
    color: #212529 !important
  .checkmark:after
    left: 50%
    top: 50%
    width: 6px
    height: 11px
    border: solid #2e7eed
    border-width: 0 3px 3px 0
    transform: rotate(45deg) translateX(-110%) translateY(-25%)
    content: ""
    position: absolute
    display: none
</style>
