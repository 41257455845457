<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="25"
    height="25"
    viewBox="0 0 25 25"
  >
    <image
      id="instagram"
      width="25"
      height="25"
      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAACnElEQVRIiZ3VW6iWVRAG4Me/nSSliZkVmFsoDEMkQi3qIiiznVqQQV5UlJaQu1sNAr2xq6ibKMEivMhCMMJIMiKhAxVGEEFQURclRhoqBWlKJ2Xk/eFD9vcfHFh8hzVr3jUz78xMGh8f15DJWIW1uBlTDS7/4Ru8i5dwuHtypGHicuzBTUMYbsoFuCHrCTyOt2u/E6W5+DIA32I15mDaEKsueRt24DLsxsNNT17EKN7DAzh+Hp78iU+yPsPWrK/KkxuxEn/hnvMEKJmEZbgIL+O15PTJAtkQpaeTvF5G5iSkC0KSpqzB+7g//zblubJAluZjdw/jRcEf8TP2h0VH8CpmRe9zvImv8/0rTuDqkSSs5O8JAKbgLYwF7Bh+SGLn4THcm7U/+WzKIVzbmcBwU97B3TiYeF+FW3AdrseuXLJqY3abkV4gjyaU5fYSfIB/GvvfheoVohkJ3VAgF+Ih/I/N+K3HZSrhv+OuhHFgkOm4BiextweA6OzJ+x3DgJQnF8eTXl6IzqG8z5pIoQ3k3xRn7c/sA9JpGD86DMgf+CnVO9YHpIpyRd4/HAakamZnOuszYU+bbIsnH7eFtheFX0kVj+Z56zn61WK245E0xzVthkbaNiJjYU618E9xIPVRVF0cnQrtfQnvhNJJqxBGnSt1wzvxVABGA7w4xHgdC/FRi/0ru+HqJmtFi2JV+XPVgzAft2c0X5GhdLDlXLWbS6pjFMjz+flsn9AVrb/Ppb4YYO5syXNvJwf24dJ03Cl9Dg8iD6ZDV6vf2mXLeKp2Vcbn8hTh5OSq3yoCVYdYlJFbuar/G2u+dNlVA6k6bfWpUqzWXVKT8vSAt28ytQizHm/UR5P3v2S0rktdnEox1uFBVl2mBtoLYdxZADgDd5KIjA5XDaYAAAAASUVORK5CYII="
    />
  </svg>
</template>
<script>
export default {
  name: 'InstagramComp'
}
</script>
<style lang="sass" scoped>
svg
  width: 15px
  height: 15px
  margin-top: -5px
  margin-right: 5px
</style>
