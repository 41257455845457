<template>
  <Modal
    id="modal-login"
    size="modal-lg"
  >
    <h6 class="text-center my-3 bold">
      Cadastre-se na plataforma do Núcleo de Gestão Municipal
    </h6>

    <form
      class="mt-2 row justify-content-center"
      @submit.prevent="cadastrar"
    >
      <div
        :key="input.key"
        v-for="input in completedInputs"
        :class="input.class"
        v-show="!input.show || input.show(form)"
      >
        <component
          :is="input.component"
          :id="input.key"
          :type="input.type"
          :max-length="input.maxLength"
          v-model="form[input.key]"
          :required="input.required"
          :mask="input.mask"
          :list="'conditional' in input ? input.conditional(form) : input.list"
          :component="input.component"
          :label="input.label"
          :placeholder="input.placeholder"
          v-if="!input.show || input.show(form)"
        />
      </div>

      <div class="col-12 text-center mt-3">
        <ButtonPink
          label="Criar cadastro"
          :height="30"
          :width="60"
        />
      </div>
    </form>
  </Modal>
</template>

<script>
import axios from 'axios'
import inputs from './formModalCadastro'
import Modal from '@/components/shared/Modal'
import ButtonPink from '@/components/shared/ButtonPink'
import InputModal from '@/components/shared/InputModal'

export default {
  components: {
    Modal,
    ButtonPink,
    InputModal
  },

  data () {
    return { inputs, form: { ...reduced() } }
  },

  computed: {
    completedInputs () {
      return this.inputs.map(item => {
        if (item.key === 'listaAreasInteresse') item.list = this.$store.getters['categorias/getList'].map(({ nome, id }) => ({ label: nome, code: id }))
        return item
      })
    }
  },

  watch: {
    async 'form.cep' (cep) {
      const validateCep = cep?.replace('-', '')
      if (!validateCep || validateCep.length < 8) return

      const { data } = await axios.get(`https://viacep.com.br/ws/${validateCep}/json/`)
      if (!data) return

      this.form.uf = data.uf
      this.form.bairro = data.bairro
      this.form.cidade = data.localidade
      this.form.rua = data.logradouro
    }
  },

  methods: {
    async cadastrar () {
      const isValidCPF = this.$f.testaCPF(this.form.cpf)
      if (!isValidCPF) return this.$sweet.sweetMensagem('<h3>CPF inválido</h3>')

      this.$sweet.sweetLoading('Estamos preparando seu cadastro')

      const [dia, mes, ano] = this.form.dataNascimento.split('/')
      const dataNascimento = `${ano}-${mes}-${dia}`
      this.form.receberNotificacao = this.form.receberNotificacao ? 'S' : 'N'
      // this.form.debitosParaOrgaoPublico = this.form.debitosParaOrgaoPublico ? 'S' : 'N'
      const list = this.form.listaAreasInteresse.map(({ code }) => ({ idCategoria: code }))
      const form = { ...this.form, dataNascimento, listaAreasInteresse: JSON.stringify(list) }

      axios.get(`https://api.cursos.ibcgp.org.br/validar-email?email=${form.email}`).then(({ data, body }) => {
        if (data?.length || body?.length) return this.$sweet.sweetWarning('Este email já está sendo utilizado', 'Acesse a plataforma!')
        axios.post('https://api.cursos.ibcgp.org.br/cadastro', form).then(() => {
          this.form = { ...reduced() }
          this.$sweet.sweetSuccess('<h5>Cadastro realizado com Sucesso</h5>', 3000)
        }).catch(() => this.$sweet.sweetError())
      }).catch(() => this.$sweet.sweetError())
    }
  }
}

const reduced = () => inputs.reduce((acc, { key, initial }) => ({ ...acc, [key]: initial }), {})

</script>

<style lang="sass" scoped>
p
  font-size: 13px !important
  cursor: pointer
  &:hover
    text-decoration: underline
</style>
