<template>
  <div>
    <div v-if="showInfos">
      <div
        :key="ind"
        class="px-2"
        v-for="(image, ind) in images"
      >
        <ModalCourse
          :id="camelize(image.nome)"
          v-if="image.detalhesCorpoModal"
          :detalhes="image.detalhesCorpoModal"
        />
      </div>
    </div>

    <div class="content-slider">
      <div class="linear-gradient-slider" />

      <VueSlickCarousel v-bind="settings">
        <div
          :key="ind"
          class="pe-2 pt-1"
          v-for="(image, ind) in images"
        >
          <ContentSlider
            :show-infos="showInfos"
            :classes="classes || image.classe || ''"
            :image="image"
            :has-zoom="hasZoom"
          />
        </div>

        <template #prevArrow>
          <ButtonSlider class="arrow-left" />
        </template>

        <template #nextArrow>
          <ButtonSlider class="arrow-right" />
        </template>

        <template #customPaging>
          <div class="custom-dot" />
        </template>
      </VueSlickCarousel>
    </div>
  </div>
</template>
<script>
import ContentSlider from './ContentSlider'
import ButtonSlider from './ButtonSlider'
import ModalCourse from '@/components/ModalCourse'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'SliderComp',
  components: {
    VueSlickCarousel,
    ButtonSlider,
    ContentSlider,
    ModalCourse
  },

  props: {
    valor: {},

    autoplay: {
      type: Boolean,
      default: false
    },

    showInfos: {
      type: Boolean,
      default: false
    },

    classes: {
      default: ''
    },

    images: {
      type: Array,
      default: () => []
    },

    hasZoom: {
      default: true
    },

    slidesToShow: {
      type: Number,
      default: 1
    },

    dots: {
      type: Boolean,
      default: false
    },

    centerMode: {
      type: String,
      default: '30px 0px 0px 0px'
    }
  },

  methods: {
    toLink (image) {
      if (!image.linkExterno) return
      const win = window.open(image.linkExterno)
      win.focus()
    },

    camelize (text) {
      return text.split(' ')
        .map(w => w.replace(/./, m => m.toUpperCase()))
        .join('')
    }
  },

  data () {
    return {
      settings: {
        speed: 700,
        infinite: true,
        dots: this.dots,
        autoplaySpeed: 4200,
        adaptiveHeight: true,
        autoplay: this.autoplay,
        centerMode: this.images.length > this.slidesToShow,
        slidesToShow: this.slidesToShow,
        centerPadding: this.centerMode,
        slidesToScroll: this.slidesToShow
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.content-slider
  position: relative
  .linear-gradient-slider
    top: 1%
    position: absolute
    z-index: 1 !important
    right: 0px
    width: 60px
    height: 97%
    background-image: linear-gradient(to right, transparent, rgba(0,0,0,.65))

  .slick-dots
    .slick-next
      right: -20px !important
    .slick-active
      .custom-dot:before
        width: 20px
        border-radius: 3px
        background-color: #000
    .custom-dot
      width: 100%
      display: inline-flex
      flex-direction: row
      position: relative
      justify-content: center
      &:before
        position: absolute
        content: ''
        width: 7px
        height: 7px
        transition: all .1s
        background-color: #c5c5c5
        border-radius: 50%
  .arrow-right
    transform: rotate(135deg)
    right: -20px !important
    top: 50%
    background-color: #272b34
  .arrow-left
    left: -20px !important
    background-color: #272b34
    transform: rotate(-45deg)
  @media (max-width: 500px)
    .arrow-left
      transform: rotate(-45deg) scale(.5)
    .arrow-right
      transform: rotate(135deg) scale(.5)
    .slick-dots
      .custom-dot:before
          width: 4px
          height: 4px
</style>
