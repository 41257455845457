<template>
  <section class="overflow-hidden">
    <div class="banner">
      <div class="container-cursos">
        <div class="row align-items-center justify-content-between py-2">
          <div class="col-12 d-md-none mb-0">
            <img
              src="@/assets/images/5.png"
              class="banner-image-sm"
            >
          </div>

          <div class="col-12 col-md-4 mt-3 pt-3">
            <h1 class="title upper text-start bold mb-4 text-white">
              Cursos preparados <br>para você
            </h1>

            <h6 class="mb-md-5 mb-4 text-start">
              Se desafie dia após dia e venha fazer parte da plataforma de cursos do Núcleo de Gestão Municipal com Professores <span class="upper">Especialistas</span> para você.
            </h6>
          </div>

          <div class="col-md-8 d-none d-md-block">
            <img
              class="banner-image"
              src="@/assets/images/5.png"
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    valor: {}
  }
}
</script>

<style lang="sass" scoped>
.banner
  max-height: 600px
  position: relative
  width: 150%
  left: 50%
  transform: translateX(-50%)
  border-radius: 0% 0% 50% 50%
  padding-top: 50px
  padding-bottom: 100px
  background-image: linear-gradient(45deg,#009ec5,#2e7eed 20%,#02225b 50%)
  .banner-image
    border-radius: 10px
h6
  color: #e5e5e5
  font-size: 16px !important
  line-height: 25px
  text-align: left !important
  font-weight: 400 !important

@media (max-width: 500px)
  .banner
    width: 100%
    border-radius: 0% 0% 5% 5%
    padding-top: 30px
    padding-bottom: 75px
  .banner-image-sm
    border-radius: 5px
label
  line-height: 20px
  font-size: 14px
.dark-checkbox
  background-color: #eee
.title
  font-size: 26px
  text-transform: none
.container-cursos
  img
    width: 100%
</style>
