import './assets/styles/styles.min.css'
import './assets/styles/bootstrap-grid.min.css'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueMask from 'v-mask'
import { funcoes } from '@/assets/js/functions'
import Sweet from '@/assets/js/sweet'
import axios from 'axios'
import VueScrollTo from 'vue-scrollto'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

const baseURL = 'https://api.cursos.ibcgp.org.br'
// const baseURL = 'https://api.pjamaiseducacao.com.br'
axios.defaults.baseURL = baseURL
Vue.prototype.$baseURL = baseURL

Vue.prototype.$telefone = {
  mask: '+55 (67) 3042-4800',
  number: '556730424800'
}
Vue.prototype.$endereco = {
  cep: '',
  maps: 'https://www.google.com/maps/place/Delta+Contabilidade+e+Assessoria/@-20.480555,-54.598586,14z/data=!4m5!3m4!1s0x0:0xa89e5bf31511351f!8m2!3d-20.4805591!4d-54.5986016?hl=pt-BR',
  desc: 'Rua Santana, 143. Jardim TV Morena - Campo Grande/MS'
}
Vue.prototype.$f = funcoes
Vue.prototype.$sweet = new Sweet()
Vue.prototype.$linkSite = 'https://ibcgp.org.br'
Vue.prototype.$emailContato = 'contato@ibcgp.org.br'
Vue.prototype.$emailSuporte = 'suporte@ibcgp.org.br'
Vue.prototype.$instagram = 'https://www.instagram.com/ibcgp/'
Vue.prototype.$linkAssomasul = 'https://www.assomasul.org.br'
Vue.prototype.$space = 'https://ibcgp-cursos.sfo3.cdn.digitaloceanspaces.com'
Vue.prototype.$instagramAssomasul = 'https://www.instagram.com/assomasul'

Vue.prototype.$oldLinkPlataforma = 'nucleodegestaomunicipal.ibcgp.org.br'
Vue.prototype.$linkPlataforma = 'https://nucleodegestaoassomasul.ibcgp.org.br/plataforma/#/login'
Vue.prototype.$oldHome = 'https://nucleodegestaoassomasul.ibcgp.org.br'

if (window.location.host === Vue.prototype.$oldLinkPlataforma) window.location.href = Vue.prototype.$oldHome

Vue.use(VueMask)
Vue.use(VueScrollTo)
Vue.component('SelectComp', vSelect)

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
