<template>
  <div id="app">
    <Header />

    <MainBanner />

    <ModalCadastro />

    <div class="bg-white pb-4">
      <div class="container-cursos">
        <StudyMaterials />
      </div>
    </div>

    <FeaturedCourse />

    <div class="container-cursos py-4 py-md-5">
      <MoreCourses />
    </div>

    <div class="bg-white py-4 py-md-5">
      <div class="container-cursos">
        <Differential />
      </div>
    </div>

    <div class="container-cursos py-4 py-md-5">
      <div class="row justify-content-center justify-content-md-between">
        <WhyPJA />
        <CommonQuestions />
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import WhyPJA from '@/components/WhyPJA'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import MainBanner from '@/components/MainBanner'
import MoreCourses from '@/components/MoreCourses'
import Differential from '@/components/Differential'
import ModalCadastro from '@/components/ModalCadastro'
import FeaturedCourse from '@/components/FeaturedCourse'
import StudyMaterials from '@/components/StudyMaterials'
import CommonQuestions from '@/components/CommonQuestions'

export default {
  name: 'App',

  components: {
    Header,
    WhyPJA,
    Footer,
    MainBanner,
    MoreCourses,
    Differential,
    ModalCadastro,
    StudyMaterials,
    FeaturedCourse,
    CommonQuestions
  },

  data () {
    return {
      valor: ''
    }
  },

  async created () {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  methods: {
    handleResize () {
      this.$store.dispatch('setWidth', window.innerWidth)
    }
  }
}
</script>

<style lang="sass">
@media (min-width: 576px)
  .container-cursos
    max-width: 540px
@media (min-width: 768px)
  .container-cursos
    max-width: 720px
@media (min-width: 992px)
  .container-cursos
    max-width: 960px
@media (min-width: 1200px)
  .container-cursos
    max-width: 1140px
.transition
  background-image: linear-gradient(0deg, #fff, black 5%, black 95%, #fff)
.container-cursos
  width: 100%
  padding-right: 15px
  padding-left: 15px
  margin-right: auto
  margin-left: auto
.bg-btn-pink
  background-color: #ff4163 !important
  color: white
  padding: 3px 7px 2px 7px !important
  border-radius: 5px
  min-height: 30px
  font-size: 14px !important
  &:hover
    color: white !important
    opacity: .75
.pulse
  animation: pulse-zoom 2000ms infinite
@keyframes pulse-zoom
  0%
    transform: scale(1)
  50%
    transform: scale(1.025)
  100%
    transform: scale(1)
.bg-white
  background: #fff !important
#app
  background-color: #fafafa
.white
  color: #fff
.secondary
  color: #c5c5c5
.bg-email-field
  background-color: #191c21
.popup-top
  background-color: #fffef2
.pink
  color: #ff4163
.dark-grey
  color: #1b1b1b
.error-state
  color: #ed163d
  font-size: 13px

.hovered:hover
  color: #2e7eed !important
a
  text-decoration: none !important
  transition: all .25s !important
  &:hover
    color: #2e7eed !important
    li, button, p, h1,h2,h3,h4,h5,h6, span
      color: #2e7eed !important
.text-transform-none
  text-transform: none !important
.font-11
  font-size: 11px !important
.font-12
  font-size: 12px !important
.font-13
  font-size: 13px !important
.font-14
  font-size: 14px !important
.font-15
  font-size: 15px !important
.font-16
  font-size: 16px !important
.font-17
  font-size: 17px !important
.font-18
  font-size: 18px !important
.font-19
  font-size: 19px !important
.font-20
  font-size: 20px !important
.font-21
  font-size: 21px !important
.font-22
  font-size: 22px !important
.font-23
  font-size: 23px !important
.font-24
  font-size: 24px !important
.font-25
  font-size: 25px !important
.font-26
  font-size: 26px !important
.font-27
  font-size: 27px !important
.font-28
  font-size: 28px !important
.font-29
  font-size: 29px !important
.font-30
  font-size: 30px !important
.font-30
  font-size: 30px !important
.font-31
  font-size: 31px !important
.font-32
  font-size: 32px !important
.font-33
  font-size: 33px !important
.font-34
  font-size: 34px !important
.font-35
  font-size: 35px !important
.font-36
  font-size: 36px !important
.font-37
  font-size: 37px !important
.font-38
  font-size: 38px !important
.font-39
  font-size: 39px !important
.font-40
  font-size: 40px !important

@media (max-width: 500px)
  .title
    font-weight: bold !important
    font-size: 20px !important
</style>
