<template>
  <div>
    <div
      data-toggle="modal"
      :data-target="`#${id}`"
      :id="`open-${id}`"
    />
    <div
      :id="id"
      class="modal fade"
    >
      <div :class="['modal-dialog', size]">
        <div :class="['bg-modal modal-content p-4 pt-2', classe]">
          <span
            class="pointer close-modal"
            data-toggle="modal"
            :id="`close-${id}`"
            :data-target="`#${id}`"
          >
            <Close />
          </span>
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Close from '@/assets/icons/Close'

export default {
  name: 'ModalComp',

  components: {
    Close
  },

  props: {
    id: {
      type: String,
      required: true
    },
    classe: {
      type: String
    },
    size: {
      type: String
    }
  }
}
</script>
<style lang="sass">
@media (max-width: 500px)
  .close-modal
    right: 5px !important
    top: 5px !important

.close-modal
  z-index: 1
  position: absolute
  right: -45px
  top: 0px
  background-color: #fff
  width: 100%
  padding: 2px
  border-radius: 50%
  width: max-content !important

.bg-modal
  background-color: #fff

%default-input
  width: 100%
  background-color: transparent
  border: none
  border-bottom: 3px solid #171717
  font-size: 14px

.input-modal
  @extend %default-input
  color: #c5c5c5

.select-modal
  @extend %default-input
  color: #c5c5c5
  &:focus
    color: #1b1b1b !important

input::-webkit-input-placeholder
  font-size: 13px
  line-height: 3

::-webkit-calendar-picker-indicator
  font-size: 13px
  filter: invert(0.8)

.select-modal::-ms-expand
  display: none !important

</style>
