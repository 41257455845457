import Vue from 'vue'
import Vuex from 'vuex'

import configs from './modules/configs.js'
import categorias from './modules/categorias.js'

Vue.use(Vuex)
const modules = { configs, categorias }
const novo = new Vuex.Store({ modules })
export default novo
