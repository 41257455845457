import { render, staticRenderFns } from "./FeaturedCourse.vue?vue&type=template&id=3e681aba&scoped=true&"
import script from "./FeaturedCourse.vue?vue&type=script&lang=js&"
export * from "./FeaturedCourse.vue?vue&type=script&lang=js&"
import style0 from "./FeaturedCourse.vue?vue&type=style&index=0&id=3e681aba&prod&lang=sass&"
import style1 from "./FeaturedCourse.vue?vue&type=style&index=1&id=3e681aba&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e681aba",
  null
  
)

export default component.exports