const SET_LIST = 'SET_LIST'
const SET_CATEGORIE = 'SET_CATEGORIE'

const state = {
  lista: [],
  categoria: {}
}

const getters = {
  getList: (state) => state.lista,

  getCategoria: (state) => state.categoria
}

const actions = {
  setList ({ commit }, payload) {
    commit(SET_LIST, payload)
  },

  setCategoria ({ commit }, payload) {
    commit(SET_CATEGORIE, payload)
  }
}

const mutations = {
  [SET_LIST] (state, lista) {
    Object.assign(state, { lista })
  },

  [SET_CATEGORIE] (state, categoria) {
    Object.assign(state, { categoria })
  }
}

export default {
  state,
  actions,
  getters,
  mutations,
  namespaced: true
}
