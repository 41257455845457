<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="49"
    height="49"
    viewBox="0 0 49 49"
  >
    <image
      id="gift"
      width="49"
      height="49"
      xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAADEAAAAxCAYAAABznEEcAAADJklEQVRoge2aSWgUQRSGv+mZUeMuaCAixIOKQQUJBlHEg0Y8uCF48KKiiEhAQTyIIOIhFxdwxYMnhYhXl1tOoiB4CIorJIpLFMUN4pIxiSFS8DqULz0zXeUoPdAfNFNvuqpe/V1d9WqqJrOquRlHWoBtQA3wCugA7gL3gPcOVQXAXGAxsARYCNQCH4DTwOW4FeUcBZwA9lv2AmCtpAeAF0AX8BDoK1LHBKARmAXMiLhfD7QBk4DzcRrl0hPmqT2Nm7kCDALjSjyMYQIHX8ut9JC8Pt0VbHQn8Niys8DMOAVdXyeb7cADoAGYAzQBG8WOwy2gXcbUM7k2A1dc2+ci4peVzgCTpUeeyHUV+AictPKtBJ5Leh1w1rrXop68YYqyBystIk7Zicq+D3yRdKe6Ny2ivMvr7VXorbJfRuT5pOweK/1d3YsaT++U3RWnYfppTgWWyqdmmbJ3R8xWq5V9wIodTTHKryhR3ua1jKl+1BR7Btgh01o1YHrtqAmMYU9cA9ZXSeND6oBTZoIxY2JXFQqwOWJ64qD6cp8sG1wwy4hjVv5W4GaR8mbdtUXSvRIbeh18mSC4BtgrdiGnproL0kWudCsRJojdLlJHgyXCrLduePhrlxg0z8SvQAJXyB2PCqOYXuJe1Mznw6OwTE5FRTPFvfGosNGzIXmJ5C6vU8jsMJFTAW+rXP+LscD1v/UVVFFcKEY+8BxYSWJMTmLEBmMAm+QK2SMr03LMBw55CCvIarbg4aNVBvdnO2IjM4ct4lzMxnR4ijBrn4uePi7Jb5ARq1i9PK6P6SBuPk0gv7l9fAzbXuv3pJGKSAqpiKSQikgKqYikkIpICqmIpJCKSAqpiKSQikgKqYikoEVkld2foLb+VHYwIiFklP01poMeZX8rkdfePB4qk9emaFv1waM+VzZn0oeBH2Uc1Cm7tsR+kn1WHcgxQDkhpr7j6rvhQ0v9347R8qRs1QMRXanJyg53SEEd3tuMEj9IT+ij4Sj0AzG7gYtCQ/dEn2ztt1nf5eVyoSZm3ozDDqBNeNIUKcJg/mdkKjav0XgPB/8Sc+y784/zb+A3F7mKWLI/JqsAAAAASUVORK5CYII="
    />
  </svg>
</template>
<script>
export default {
  name: 'GiftComp'
}
</script>
<style lang="sass" scoped>
svg
  width: 45px
  height: 45px
@media (max-width: 500px)
  svg
    width: 35px
    height: 35px
</style>
