<template>
  <div :class="['banner', showInfos ? 'banner-hover' : '']">
    <img
      :src="image.link"
      :class="['image-course', 'categoria-default', hasZoom && 'zoom']"
    >

    <div
      v-if="showInfos"
      class="form-infos w-100"
    >
      <div class="conten-info p-3 pt-4 pb-5 pb-md-3 mb-md-0 mb-2 d-flex flex-column h-75 justify-content-around align-content-around">
        <h6 class="bold text-white text-center font-22 mb-2">
          {{ image.nome }}
        </h6>

        <p class="mb-2 apresentacao">
          {{ image.apresentacao }}
        </p>

        <div class="row justify-content-center">
          <div class="col-auto">
            <span
              class="btn btn-light px-2 py-0 font-12 text-dark bold"
              @click="setCategoria(image)"
            >
              Ver conteúdo da área
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ContentComp',

  props: {
    showInfos: {
      type: Boolean,
      default: false
    },

    classes: {
      default: ''
    },

    image: {
      type: Object,
      default: () => {}
    },

    hasZoom: {
      default: true
    }
  },

  methods: {
    toLink (image) {
      if (!image.linkExterno) return
      const win = window.open(image.linkExterno)
      win.focus()
    },

    setCategoria (categoria) {
      this.$store.dispatch('categorias/setCategoria', categoria)
      setTimeout(() => document.getElementById('open-modal-cadastro-login').click(), 500)
    },

    camelize (text) {
      return text.split(' ')
        .map(w => w.replace(/./, m => m.toUpperCase()))
        .join('')
    }
  },

  computed: {
    heightButton () {
      return this.$store.getters.getWidth < 500 ? 22 : 24
    }
  }
}
</script>

<style lang="sass" scoped>
.apresentacao
  overflow: hidden
  display: -webkit-box
  -webkit-line-clamp: 2
  -webkit-box-orient: vertical
.banner
  border-radius: 5px
.banner-hover
  position: relative
  overflow: hidden
  padding-bottom: 1px
  .footer
    position: absolute
    bottom: 0px
    z-index: 1
    background-color: #0a0a0a
    width: 100%
    border-bottom-left-radius: 1px
    border-bottom-right-radius: 1px
  .form-infos
    border-radius: 3px
    height: 100%
    position: absolute
    top: 100%
    transition: all .55s
    background: #00000066
    top: 75% !important
    border-top-left-radius: 8px
    border-top-right-radius: 8px
    backdrop-filter: blur(8px)
    .content-img
      position: relative
      .linear-gradient
        bottom: 0px
        position: absolute
        z-index: 1 !important
        width: 100%
        height: 50px
        background-image: linear-gradient(transparent, black)
  &:hover
    .image-course
      transition: .75s
    .form-infos
      border-top-left-radius: 10px
      border-top-right-radius: 10px
      top: 20% !important
      .apresentacao
        -webkit-line-clamp: 50

.image-course
  width: 100%
  margin: auto
  min-height: 450px
  transition: all .25s
h5
  background-color: #0003
p
  color: #fff
  font-size: 13px
.secondary
  color: #c5c5c5 !important
.zoom
  &:hover
    transform: scale(1.03)
    filter: grayscale(.2)
@media (max-width: 500px)
  p
    font-size: 14px !important
  .conten-info
    overflow: scroll !important
    max-height: -webkit-fill-available
    max-height: 50%
  .image-course
    min-height: 250px !important
  .footer
    p
      font-size: 11px !important
  h6
    font-size: 14px !important
</style>
