<template>
  <div :class="[$slots.prepend ? 'input-group' : '', 'mb-3', 'field-input', classe]">
    <label
      :for="id"
      :class="['form-label bold text-start w-100 font-14 pointer user-select-none mb-0', { bold }, labelClasse]"
    >
      {{ label }}
    </label>

    <select
      :id="id"
      v-model="selectedOption"
      :disabled="disabled"
      class="form-control"
    >
      <option
        v-for="(item, indItem) in list"
        :key="indItem"
        :value="item.value || item.id || item"
      >
        {{ item.name || item.label || item }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    value: {},

    labelClasse: {
      type: String
    },

    bold: {
      type: Boolean,
      default: false
    },

    label: {
      type: String
    },

    required: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    id: {
      type: String
    },

    classe: {
      type: String
    },

    list: {
      default: () => []
    }
  },
  data () {
    return {
      selectedOption: this.value
    }
  },

  watch: {
    value: {
      handler (value) {
        if (value === this.selectedOption) return
        this.selectedOption = value
      }
    },

    selectedOption: {
      handler (selectedOption) {
        if (selectedOption === this.value) return
        this.$emit('input', selectedOption)
      }
    }
  }
}
</script>
<style lang="sass" scoped>
select:focus
  color: #c5c5c5 !important
select
  height: 35px !important
.select-modal
  border-radius: 2px
  border: 1px solid #606060 !important
  padding: 8px 10px 5px 10px !important
  &:focus
    border: 1px solid #002582 !important
</style>
