<template>
  <div :class="[$slots.prepend ? 'input-group' : '', 'mb-3', 'field-input', classe]">
    <label
      :for="id"
      :class="['form-label bold text-start w-100 font-14 pointer user-select-none mb-0', { bold }, labelClasse]"
    >
      {{ label }}
    </label>

    <slot
      name="prepend"
      class="prepend"
    />

    <input
      :id="id"
      :disabled="disable"
      :maxlength="maxLength"
      v-mask="mask"
      :type="type"
      :required="required"
      v-model="field"
      :placeholder="placeholder"
      :class="[inputClasse || 'form-control']"
    >
  </div>
</template>

<script>
export default {
  props: {
    id: {},
    bold: {
      default: false,
      type: Boolean
    },
    type: {},
    label: {},
    classe: {},
    maxLength: {},
    disable: {
      type: Boolean,
      default: false
    },
    value: {},
    labelClasse: {},
    required: {
      default: false,
      type: Boolean
    },
    upperCase: {
      default: false,
      type: Boolean
    },
    mask: {},
    inputClasse: {},
    placeholder: {}
  },

  data () {
    return {
      field: this.value
    }
  },

  watch: {
    field (field) {
      if (this.$f.isEqual(field, this.value)) return
      const newVal = this.upperCase ? field.toUpperCase(field) : field
      this.$emit('input', newVal)
    },

    value (value) {
      if (this.$f.isEqual(value, this.field)) return
      const newVal = this.upperCase ? value.toUpperCase(value) : value
      this.field = newVal
    }
  }
}
</script>

<style lang="sass">
.field-input
  i
    display: flex
    color: #212529 !important
    font-size: 1rem !important
    font-weight: 600 !important
    line-height: 1.5 !important
    text-align: center !important
    white-space: nowrap !important
    align-items: center !important
    border-radius: 0.375rem !important
    padding: 0.375rem 0.75rem !important
    background-color: #f8f9fa !important
    border: 1px solid #dee2e6 !important

  .input-group-prepend
    .fa
      height: 100% !important
      padding-top: 8px !important
      height: -webkit-fill-available
      border-top-right-radius: 0px !important
      border-bottom-right-radius: 0px !important

  .form-check-input
    font-size: inherit
  input
    font-size: 14px
    color: #444 !important
    font-weight: 400 !important
    &::placeholder
      opacity: .5 !important
      font-size: 14px
</style>
