<template>
  <Modal
    :id="id"
    v-if="curso.id"
    classe="pe-2"
  >
    <div class="pt-2 pe-1 scrolling-modulos">
      <h4 class="w-100 text-center font-20 mb-3">
        {{ curso.nome }}
      </h4>

      <p
        class="bold"
        v-if="curso.valor"
      >
        Investimento:
      </p>
      <p
        class="mb-3 dark-grey-modal"
        v-if="curso.valor"
      >
        {{ curso.valor }}
      </p>
      <p
        class="mb-3 text-success bold"
        v-if="!curso.valor"
      >
        Gratuito
      </p>

      <div
        v-if="curso.ementario"
        v-html="curso.ementario"
        class="pb-3 dark-grey-modal mb-3"
      />

      <div class="text-center">
        <button
          class="btn btn-primary upper font-12"
          @click="inscrever"
        >
          cadastrar-se na plataforma
        </button>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from '@/components/shared/Modal'

export default {
  props: {
    curso: {},
    id: {}
  },

  components: { Modal },

  methods: {
    inscrever () {
      document.getElementById(`close-${this.id}`).click()
      setTimeout(() => document.getElementById('open-modal-cadastro-login').click(), 500)
    }
  }
}
</script>
<style lang="sass" scoped>
h4
  font-size: 16px
  font-weight: 600
h6
  color: #2e7eed
  font-size: 16px

::-webkit-scrollbar
  width: 3px
  background: #aaa !important
  border-radius: 3px
::-webkit-scrollbar-track
  border-radius: 3px
::-webkit-scrollbar-thumb
  max-height: 50px
  border-radius: 3px
  background: #2e7eed
.scrolling-modulos
  max-height: 85vh
  overflow: scroll
  overflow-x: hidden
.dark-grey-modal
  border-radius: 5px
  background-color: #f2f2f2
  padding: 10px 15px 5px 15px
  margin-bottom: 15px
  color: #333
  font-size: 14px
  line-height: 20px
.list-modal
  margin: 0px
  padding: 0px
  list-style: none
  li
    color: #777
    font-size: 14px
.informacoes
  svg
    fill: #2e7eed
</style>
