const SET_WIDTH = 'SET_WIDTH'

const state = {
  width: 0
}

const getters = {
  getWidth: (state) => state.width
}

const actions = {
  setWidth ({ commit }, args) {
    commit(SET_WIDTH, args)
  }
}

const mutations = {
  [SET_WIDTH] (state, width) {
    Object.assign(state, { width })
  }
}

export default {
  state,
  actions,
  getters,
  mutations
}
