<template>
  <footer>
    <div class="footer-main">
      <div class="container-cursos">
        <div class="row px-2 px-md-0">
          <div class="col-lg-3 col-md-12 col-12 m-md-auto align-self-center">
            <div class="block text-center text-md-start mb-4 mb-md-0 logos">
              <a :href="$linkSite">
                <img
                  class="mb-4 logo-ibcgp"
                  src="@/assets/images/logo-branca.png"
                >
              </a>

              <a :href="$linkAssomasul">
                <img
                  src="@/assets/images/logo-assomasul-branca.png"
                  class="logo-assomasul"
                >
              </a>
            </div>
          </div>

          <div class="col-lg-5 col-md-6 col-12 mt-md-5 mt-3 mt-lg-0">
            <div class="block-2">
              <h6 class="mb-2 mb-md-4">
                Contato
              </h6>

              <ul>
                <li>
                  Telefone: <a :href="`tel:${$telefone.number}`">{{ $telefone.mask }}</a>
                </li>

                <li>
                  E-mail: <a :href="`mailto:${$emailContato}`">{{ $emailContato }}</a>
                </li>

                <li>
                  Endereço:
                  <a
                    target="_blank"
                    :href="$endereco.maps"
                  >
                    {{ $endereco.desc }}
                  </a>
                </li>

                <li>
                  CEP:
                  <a
                    target="_blank"
                    :href="$endereco.maps"
                  >
                    79051-200
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2 col-md-3 col-12 mt-md-5 mt-3 mt-lg-0">
            <div class="block-2">
              <h6 class="mb-2 mb-md-4">
                Redes sociais
              </h6>
              <ul class="social-icon">
                <li>
                  <a
                    target="_blank"
                    :href="$instagram"
                  >
                    <Instagram /> IBCGP
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    :href="$instagramAssomasul"
                  >
                    <Instagram /> Assomasul
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div class="col-lg-2 col-md-3 col-12 mt-md-5 mt-3 mt-lg-0">
            <div class="block-2">
              <h6 class="mb-2 mb-md-4">
                Mapa do site
              </h6>
              <ul>
                <li>
                  <a :href="`${$linkSite}/#/sobre`">Conheça o Instituto</a>
                </li>

                <li>
                  <a :href="`${$linkSite}/#/concursos`">Concursos Municipais</a>
                </li>

                <li>
                  <a :href="`${$linkSite}/#/publicacoes`">Nossas Publicações</a>
                </li>

                <li>
                  <a :href="`${$linkSite}/#/contato`">Entre em contato</a>
                </li>

                <li>
                  <a :href="`${$linkAssomasul}/#/contato`">Assomasul</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center bg-dark py-1">
      <small class="text-white">
        <p>
          <span class="px-1">
            Copyright © {{ currentYear }}
          </span> |

          <span class="px-1">
            <a
              class="text-white"
              :href="`${$linkSite}`"
            >IBCGP</a>
          </span> |

          <span class="px-1">
            Todos os Direitos Reservados
          </span>
        </p>
      </small>
    </div>
  </footer>
</template>

<script>
import Instagram from '@/assets/icons/Instagram'

export default {
  name: 'FooterComp',

  components: {
    Instagram
  },

  data () {
    const data = new Date()
    return {
      currentYear: data.getFullYear()
    }
  }
}
</script>
<style lang="sass" scoped>
@media (max-width: 600px)
  .footer-main
    padding: 45px 0 !important
  .logos
    display: inline-flex
    justify-content: center
    width: 100%
@media (min-width: 800px)
  .logo-assomasul
    margin-left: -20px

.logo-assomasul
  width: 180px
.logo-ibcgp
  width: 135px
.footer-main
  padding: 92px 0
  background: #1a1b1f

.text-secondary
  p
    font-weight: 100
    a
      color: #fff !important
.bg-dark
  background-color: #343a40!important
ul
  padding: 0px
  li
    list-style: none
    font-size: 15px
    letter-spacing: 0
    line-height: 25px
    color: grey
    margin-bottom: 10px
    a
      font-size: 14px
      color: #6f6f71
      transition: .2s ease
      &:hover
        color: #fff !important
h6
  font-weight: 700
  font-size: 13px
  text-transform: uppercase
  color: #fff
</style>
