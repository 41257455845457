<template>
  <div>
    <Modal
      id="modal-cadastro-login"
      size="modal-sm"
    >
      <button
        @click="login"
        class="btn btn-primary mb-2 mt-3 font-13"
      >
        Fazer meu login
      </button>

      <button
        class="btn btn-primary font-13"
        @click="cadastrar"
      >
        Sou novo, me cadastrar
      </button>
    </Modal>

    <section
      id="cursos"
      v-if="images.length"
    >
      <Slider
        :images="images"
        :show-infos="true"
        :slides-to-show="slidesToShow"
      />
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import Modal from '@/components/shared/Modal'
import Slider from '@/components/shared/Slider'

export default {
  components: { Slider, Modal },

  created () {
    this.load()
  },

  methods: {
    login () {
      window.location.href = this.$linkPlataforma
    },

    cadastrar () {
      document.getElementById('close-modal-cadastro-login').click()
      setTimeout(() => document.getElementById('header-modal-cadastro').click(), 500)
    },

    async load () {
      const { data, status } = await axios.get('/categorias')

      if (status > 299 || status < 199) return setTimeout(() => this.load(), 3000)

      const images = data.map(item => ({ ...item, classe: `categoria-${item.id}`, link: `${this.$space}/curso-categoria/${item.id}.${item.extensao_banner_vertical}` }))
      this.$store.dispatch('categorias/setList', images)
    }
  },

  computed: {
    slidesToShow () {
      return this.$store.getters.getWidth < 500 ? 2 : 4
    },

    images () {
      return this.$store.getters['categorias/getList']
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  font-size: 24px
  font-weight: bold
  color: #444 !important
</style>
