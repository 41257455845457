import { render, staticRenderFns } from "./DirectionGraph.vue?vue&type=template&id=b89c4e8c&scoped=true&"
var script = {}
import style0 from "./DirectionGraph.vue?vue&type=style&index=0&id=b89c4e8c&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b89c4e8c",
  null
  
)

export default component.exports