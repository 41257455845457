<template>
  <section
    id="diferenciais"
    class="row justify-content-between"
  >
    <div class="col-12 col-md-6 img-smart-technology mb-md-0 mb-4">
      <img src="@/assets/images/mission.jpg">
    </div>

    <div class="col-12 col-md-5 mt-md-0 mt-2">
      <div class="box-diferential">
        <div class="p-3 p-md-4">
          <h1 class="mb-3 mb-md-4 bold bold-xs-none text-center title">
            Diferenciais da plataforma
          </h1>

          <div
            class="bordered-bottom inline-flex py-3"
            v-for="(point, ind) in points"
            :key="`point-${ind}`"
          >
            <div class="pe-2">
              <h1 class="dark-grey number">
                0{{ ind + 1 }}
              </h1>
            </div>

            <div class="ps-2">
              <h3 class="mb-1 mb-md-2">
                {{ point.title }}
              </h3>
              <p class="secondary description">
                {{ point.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'DifferntialComponent',
  props: {
    valor: {}
  },

  computed: {
    points () {
      return [
        { title: 'Fórum interativo', description: 'Dúvidas ou informações sobre assuntos de interesse da gestão pública? Contribua participando de um fórum já existente ou criando um novo grupo na área de interesse.' },
        { title: 'Atende quando e onde quiser', description: 'Nossa plataforma é adaptável à tela de qualquer dispositivo, permitindo que você assista de onde e como quiser.' },
        { title: 'Conteúdo exclusivo', description: 'Nossos cursos são originais, com profissionais competentes e atuantes nas suas respectivas áreas.' }
      ]
    }
  }
}
</script>
<style lang="sass" scoped>
.box-diferential
  background-color: white
  box-shadow: 0 7px 20px 0 rgba(0,0,0,.08)
  border-radius: 5px
h3
  font-size: 16px
  color: #444
  font-weight: bold
.dark-grey
  color: #555 !important
.title
  color: #444 !important
  font-size: 24px
.bordered-bottom
  border-bottom: 1px solid #ddd

.img-smart-technology img
  max-width: 100%
  border-radius: 5px

.description
  font-size: 15px
  line-height: 20px
  color: #777 !important
.number
  font-size: 42px !important
  color: #2e7eed !important
.dark-grey
  font-size: 60px
  height: 65px
  font-weight: 200

.inline-flex
  align-items: end
  display: inline-flex
  flex-direction: row

@media (max-width: 500px)
  .img-smart-technology
    text-align: center
    img
      max-width: 90%

  .description
    font-size: 14px
    font-weight: 200

  .number
    font-size: 40px
</style>
