// import swal from 'sweetalert2'
import swal from 'sweetalert2/dist/sweetalert2.all.min.js'

const messageColor = '#333'
const iconColor = '#002582'
const backgroundColor = '#fff'
const cancelButtonColor = '#d33'
const suport = 'suporte@ibcgp.com'
const confirmButtonColor = '#002582'

export default class sweetAlert {
  constructor () {
    this.swalWithBootstrapButtons = swal.mixin({
      allowEscapeKey: false,
      allowOutsideClick: false
    })
  }

  sweetWarning (title = '', msg = '') {
    swal.fire({
      icon: 'warning',
      iconColor: iconColor,
      showCloseButton: true,
      html: `<h5>${msg}</h5>`,
      showConfirmButton: true,
      showCancelButton: false,
      background: backgroundColor,
      confirmButtonColor: confirmButtonColor,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok',
      title: `<h4><strong>${title}</strong></h4>`
    })
  }

  sweetClose () {
    swal.fire({
      timer: 1,
      showConfirmButton: false,
      background: backgroundColor
    })
  }

  sweetNotification (html) {
    swal.fire({
      html,
      timer: 5111,
      type: 'info',
      color: messageColor,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      background: backgroundColor,
      confirmButtonColor: confirmButtonColor,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok'
    })
  }

  sweetNotificationSlim (msg) {
    swal.fire({
      customClass: {
        container: 'p-0',
        content: 'p-0',
        htmlContainer: 'p-0'
      },
      html: msg,
      type: 'info',
      padding: '0px',
      color: messageColor,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      background: backgroundColor
    })
  }

  sweetLoading (msg) {
    swal.fire({
      text: `${msg}`,
      title: 'AGUARDE',
      position: 'center',
      allowEscapeKey: false,
      timerProgressBar: true,
      showConfirmButton: false,
      allowOutsideClick: false,
      background: backgroundColor,
      didOpen: () => swal.showLoading(),
      onBeforeOpen: () => swal.showLoading()
    })
  }

  sweetSuccess (html, timer = 1111) {
    swal.fire({
      html,
      timer,
      type: 'success',
      icon: 'success',
      title: 'Sucesso',
      position: 'center',
      showConfirmButton: false,
      background: backgroundColor
    })
  }

  sweetInvalidToken () {
    return this.swalWithBootstrapButtons.fire({
      type: 'warning',
      icon: 'warning',
      title: 'Token expirado',
      confirmButtonText: 'Ok',
      showCancelButton: false,
      background: backgroundColor,
      confirmButtonColor: confirmButtonColor
    })
  }

  sweetInvalidAccess () {
    return this.swalWithBootstrapButtons.fire({
      type: 'warning',
      icon: 'warning',
      confirmButtonText: 'Ok',
      showCancelButton: true,
      background: backgroundColor,
      confirmButtonColor: confirmButtonColor,
      html: '<p>Ops, parece que outro dispositivo se conectou a sua conta. Você será redirecionada para a tela de login. Caso não tenha sido você a acessar, faça login novamente optando por atualizar o endereço de acesso e então, por segurança, atualize sua senha de acesso.</p>'
    })
  }

  sweetQuestion (html) {
    return this.swalWithBootstrapButtons.fire({
      width: '450px',
      type: 'warning',
      html: `${html}`,
      icon: 'question',
      reverseButtons: true,
      showCancelButton: true,
      background: backgroundColor,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Continuar',
      cancelButtonColor: cancelButtonColor,
      confirmButtonColor: confirmButtonColor
    })
  }

  async sweetInput (type = 'email', placeholder = 'Informe seu email') {
    return swal.fire({
      input: type,
      inputPlaceholder: placeholder,
      confirmButtonColor: confirmButtonColor
    })
  }

  sweetMensagem (msg) {
    swal.fire({
      html: msg,
      timer: 5111,
      type: 'info',
      confirmButtonColor,
      color: messageColor,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: true,
      background: backgroundColor,
      confirmButtonText: '<i class="fa fa-thumbs-up"></i> Ok'
    })
  }

  sweetMensagemEmpty (msg) {
    swal.fire({
      customClass: {
        content: 'p-0',
        container: 'p-0',
        htmlContainer: 'p-0',
        closeButton: 'btn-primary'
      },
      html: msg,
      type: 'info',
      padding: '0px',
      color: messageColor,
      showCloseButton: true,
      showCancelButton: false,
      showConfirmButton: false,
      background: backgroundColor
    })
  }

  sweetError () {
    swal.fire({
      timer: 5111,
      type: 'error',
      icon: 'error',
      position: 'center',
      showCloseButton: true,
      showConfirmButton: false,
      background: backgroundColor,
      title: 'Ops... Algo deu errado',
      text: 'Por favor, tente novamente!',
      html: `<p><strong>Por favor, tente novamente!</p></strong><hr><p>Se o erro continuar, entre em contato com o suporte ${suport}.</p>`
    })
  }

  sweetErrorPassword () {
    swal.fire({
      timer: 5111,
      type: 'error',
      icon: 'error',
      position: 'center',
      showCloseButton: true,
      showConfirmButton: false,
      background: backgroundColor,
      title: 'Ops... Algo deu errado',
      text: 'Por favor, tente novamente!',
      html: `<p><strong>Senha atual informada e a registrada na base não coincidem!</p></strong><hr><p>Se o erro continuar, entre em contato com o suporte ${suport}.</p>`
    })
  }

  sweetSuccessResp (msg, timer = 1111) {
    swal.fire({
      timer,
      html: `${msg}`,
      type: 'success',
      icon: 'success',
      position: 'center',
      showConfirmButton: false,
      background: backgroundColor
    })
  }

  sweetQuestionPersonalizado (title, html) {
    return this.swalWithBootstrapButtons.fire({
      width: '450px',
      type: 'warning',
      icon: 'warning',
      html: `${html}`,
      title: `${title}`,
      cancelButtonColor,
      confirmButtonColor,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Voltar',
      background: backgroundColor
    })
  }

  sweetConfirm (msg) {
    return this.swalWithBootstrapButtons.fire({
      width: '450px',
      title: `${msg}`,
      type: 'warning',
      icon: 'warning',
      confirmButtonColor,
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Voltar',
      background: backgroundColor,
      cancelButtonColor: cancelButtonColor
    })
  }

  sweetCancel (msg) {
    this.swalWithBootstrapButtons.fire({
      timer: 2500,
      type: 'error',
      icon: 'error',
      text: `${msg}`,
      title: 'Cancelado',
      allowOutsideClick: true,
      background: backgroundColor
    })
  }
}
