<template>
  <div
    v-if="cursos.length"
    class="bg-black py-4 py-md-5 transition"
  >
    <div class="container-cursos">
      <Carousel v-bind="settings">
        <Slide
          v-for="(curso, ind) in cursos"
          :key="`banner-curso-${ind}`"
          class="row justify-content-between w-100 d-inline-flex px-5 mx-auto"
        >
          <div class="col-12 col-md-4 img-smart-technology mb-md-0">
            <img :src="curso.image">
          </div>

          <div class="col-12 col-md-7 mt-md-0 mt-2">
            <div class="box-diferential">
              <div class="p-3 p-md-4">
                <h1 class="mb-3 mb-md-4 bold bold-xs-none text-center title font-22">
                  {{ curso.nome }}
                </h1>

                <p class="text-white bold font-15 mb-4">
                  <span class="bold">Carga horária: </span>
                  <span class="opacity-75 font-14">{{ curso.carga_horaria }} horas</span>
                </p>

                <p class="text-white bold font-15">
                  Público alvo:
                </p>
                <p class="text-white mb-4 opacity-75 font-14" v-html="curso.publico_alvo" />

                <p class="text-white bold font-15">
                  Objetivo:
                </p>
                <p class="text-white mb-4 opacity-75 font-14" v-html="curso.objetivo" />

                <div class="text-center">
                  <button
                    class="btn btn-primary py-0"
                    @click="openCourse(curso)"
                  >
                    Saiba mais
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Slide>
      </Carousel>

      <ModalCourse
        :curso="currentCurso"
        id="sobre-o-curso"
      />
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import axios from 'axios'
import { Carousel, Slide } from 'vue-carousel'
import ModalCourse from '@/components/ModalCourse'
import ButtonSlider from '@/components/shared/ButtonSlider'

export default {
  props: {
    valor: {}
  },

  components: {
    Slide,
    Carousel,
    ModalCourse,
    ButtonSlider
  },

  data () {
    return {
      currentCurso: {},
      cursos: [],
      settings: {
        perPage: 1,
        scrollPerPage: true,
        autoplay: true,
        navigationEnabled: true,
        paginationEnabled: false,
        autoplayTimeout: 2500
      }
    }
  },

  async created () {
    const { data: cursos } = await axios.get('/cursos-destaque')
    for (const curso of cursos) this.downloadImage(curso)
  },

  methods: {
    openCourse (curso) {
      this.currentCurso = curso
      setTimeout(() => document.getElementById('open-sobre-o-curso').click(), 500)
    },

    async downloadImage (curso) {
      console.log(curso);
      const b = curso.extensao_banner_vertical
      const isExt = b === 'jpg' || b === 'jpeg' || b === 'png'
      const route = !isExt ? b : `/arquivo?diretorio=curso&identificador=${curso.id}&extensao=${curso.extensao_banner_vertical}`
      console.log(route);

      const { data, status } = await axios.get(route, { responseType: 'arraybuffer' }).catch(err => err)
      if (data === 'arquivo inexistente' || status >= 300) return

      const TYPED_ARRAY = new Uint8Array(data)
      const STRING_CHAR = TYPED_ARRAY.reduce((data, byte) => `${data}${String.fromCharCode(byte)}`, '')
      const src = isExt ? `data:image/png;base64, ${btoa(STRING_CHAR)}` : b

      curso.image = src
      this.cursos = [...this.cursos, curso]
    },

    camelize (text) {
      return text.split(' ')
        .map(w => w.replace(/./, m => m.toUpperCase()))
        .join('')
    }
  }
}
</script>

<style lang="sass">
.VueCarousel-navigation-next
  &:after
    transform: translateX(60%) translateY(35%) rotate(135deg) !important

.VueCarousel-navigation-button
  position: relative
  color: transparent !important
  user-select: none
  &:active, &:focus
    outline: none !important

  &:before
    top: 0
    left: 0
    content: ''
    z-index: -1
    width: 40px
    height: 40px
    position: absolute
    border-radius: 50%
    background-color: #fff
    box-shadow: 0px 0px 2px 3px rgba(45, 45, 45, 0.25)
  &:after
    z-index: -1
    left: 0
    width: 15px
    height: 15px
    content: ''
    position: absolute
    border-top: 3px solid #2e7eed
    border-left: 3px solid #2e7eed
    transform: translateX(100%) translateY(35%) rotate(-45deg)

.slick-slide
  text-align: center !important
</style>

<style lang="sass" scoped>
#diferenciais
  width: 100% !important
  text-align: center
.box-diferential
  background-color: #111
  box-shadow: 0 7px 20px 0 rgba(0,0,0,.08)
  border-radius: 5px
p
  text-align: left !important
h3
  font-size: 16px
  color: #444
  font-weight: bold
.dark-grey
  color: #555 !important
.title
  color: #fff !important
.bordered-bottom
  border-bottom: 1px solid #ddd

.img-smart-technology img
  max-width: 100%
  border-radius: 5px

.description
  font-size: 15px
  line-height: 20px
  color: #777 !important
.number
  font-size: 42px !important
  color: #2e7eed !important
.dark-grey
  font-size: 60px
  height: 65px
  font-weight: 200

.inline-flex
  align-items: end
  display: inline-flex
  flex-direction: row

@media (max-width: 500px)
  .img-smart-technology
    text-align: center
    img
      margin: auto
      max-width: 90%

  .description
    font-size: 14px
    font-weight: 200

  .number
    font-size: 40px
</style>
