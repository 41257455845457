<template>
  <div>
    <p class="label bold font-14">
      {{ label }}
    </p>

    <SelectComp
      :disabled="isDisabled"
      :options="list || []"
      v-model="field"
      :multiple="true"
      placeholder="Selecione as opções"
    />
  </div>
</template>
<script>
export default {
  props: {
    value: {},
    label: {},
    list: {},
    required: {},
    isDisabled: {}
  },

  data () {
    return {
      field: ''
    }
  },

  mounted () {
    this.field = this.value
  },

  watch: {
    value: {
      handler (value) {
        if (this.$f.isEqual(value, this.field)) return
        this.field = value
      }
    },

    field: {
      handler (field) {
        if (this.$f.isEqual(field, this.value)) return
        this.$emit('input', field)
      }
    }
  }
}
</script>
<style>
.vs__selected-options {
  box-sizing: border-box;
}
.vs__search {
  box-sizing: border-box;
  max-height: 24px;
}
input::placeholder {
  color: #888 !important;
  font-weight: 400;
  font-size: 12px !important;
  opacity: 1;
}
</style>
