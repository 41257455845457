<template>
  <section class="col-md-6 col-11 mt-3 mt-md-0 px-2 px-md-4">
    <h1 class="mb-4 bold bold-xs-none text-center title">
      Perguntas frequentes
    </h1>

    <div class="row justify-content-center">
      <div
        :key="`question-${indQuestion}`"
        class="box-question mb-4 align-items-start col-12"
        v-for="(question, indQuestion) in questions"
      >
        <div class="col">
          <div class="d-grid">
            <div>
              <div
                class="pointer"
                @click="changeQuestion(question)"
              >
                <h4>
                  {{ question.title }}
                </h4>
              </div>

              <div
                class="answer pb-3"
                v-if="question.isOpen"
              >
                <div
                  v-for="(quest, ind) in question.topics"
                  :key="`${ind}-quest`"
                >
                  <h6 class="mt-4 mb-1 bold secondary">
                    {{ quest.duvida }}
                  </h6>
                  <p
                    v-html="quest.resposta"
                    class="secondary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-auto pointer"
          @click="question.isOpen = !question.isOpen"
        >
          <Chevron :class="question.isOpen ? 'chevron-oponed' : 'chevron-closed'" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Chevron from '@/assets/icons/Chevron'

export default {
  components: {
    Chevron
  },

  methods: {
    changeQuestion (question) {
      this.questions = this.questions.map(q => ({ ...q, isOpen: (q.title === question.title && !question.isOpen) }))
    }
  },

  data () {
    return {
      questions: [
        {
          title: 'Acesso',
          topics: [
            {
              duvida: 'Como acessar uma plataforma?',
              resposta: `Ao se cadastrar você receberá um e-mail e uma senha para seu primeiro acesso. Caso não tenha recebido o e-mail com as instruções, verifique novamente na caixa de SPAM e, se o problema persistir, entre em contato conosco por meio do <a href="mailto:${this.$emailSuporte}">${this.$emailSuporte}</a>. Você poderá substituir essa senha provisória por uma de sua preferência no menu perfil.`
            },
            {
              duvida: 'Quanto tempo tenho de acesso?',
              resposta: 'Seu cadastro permite acesso somente aos cursos gratuitos, dos quais você se inscreveu e também aos adquiridos.'
            }
          ],
          isOpen: false
        },
        {
          title: 'Treinamento',
          topics: [
            {
              duvida: 'O treinamento é online?',
              resposta: 'Possuímos cursos online (em formato de vídeos) e presenciais. Para os cursos online, o acesso é disponibilizado imediatamente após a confirmação do pagamento.'
            },
            {
              duvida: 'Qual o tempo de acesso à plataforma?',
              resposta: 'Você poderá assistir quando e quantas vezes quiser durante o período vigente do plano anual.'
            },
            {
              duvida: 'Quando começar as aulas?',
              resposta: 'No ato da assinatura você já terá acesso às aulas.'
            },
            {
              duvida: 'Terei acesso às atualizações de cursos online na plataforma?',
              resposta: 'Sim.'
            },
            {
              duvida: 'Como participar do grupo dos alunos no Facebook?',
              resposta: 'Ao assinar a plataforma, você receberá um e-mail de boas-vindas e terá todas as informações e links de acesso.'
            },
            {
              duvida: 'Vocês disponibilizam os Softwares?',
              resposta: 'Não. Oferecemos apenas o treinamento para uso, pois não nos responsabilizamos pelo método que você adquiriu os softwares.'
            },
            {
              duvida: 'O curso emite certificado?',
              resposta: 'Sim. Ao concluir as aulas de um curso online (em vídeo) ou ao marcar sua presença em um curso presencial, seu certificado ficará disponível na plataforma em um período de 24 horas.'
            }
          ],
          isOpen: false
        },
        {
          title: 'Login/Segurança',
          topics: [
            {
              duvida: 'Posso compartilhar meu login?',
              resposta: 'Não. O acesso é individual e intransferível. Caso nosso sistema antifraude reconheça que dois ou mais logins estejam sendo realizados de dois lugares distintos e ao mesmo tempo, o seu acesso é bloqueado.'
            },
            {
              duvida: 'Posso acessar uma plataforma de dois ou mais dispositivos (tablet, mobile, notebook, pc) ao mesmo tempo no mesmo local?',
              resposta: 'Não. Nosso sistema permite apenas um login por vez. Ao tentar logar em outro dispositivo, o seu acesso atual está desconectado.'
            },
            {
              duvida: 'Vou viajar. Posso acessar de outra cidade?',
              resposta: 'Sim, mas lembre-se, não é permitido dois acessos ao mesmo tempo de locais diferentes. Lembre-se de deslogar de um dos dispositivos para que não haja bloqueio da sua conta.'
            }
          ],
          isOpen: false
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.title
  font-size: 24px
  color: #444 !important

@media (max-width: 500px)
  .box-question
    padding: 12px 25px !important
    padding-right: 10px !important
h4
  font-size: 16px !important
  font-weight: bold
  color: #333
  padding-top: 3px !important
h6
  font-size: 14px !important
  color: #444
  font-weight: bold
p
  font-size: 14px !important
  color: #444
.chevron-closed
  transition: .5s
  transform: rotate(180deg)
.chevron-opened
  transition: .5s
  transform: rotate(0deg)
.answer
  animation: collapse 300ms
  .secondary
    a
      color: #0d6efd !important
@keyframes collapse
  0%
    opacity: 0
    height: 0px
  70%
    opacity: .5
  100%
    opacity: 1
    height: 50vh
.box-question
  background-color: white
  box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.08)
  border-radius: 5px
  align-items: center
  display: inline-flex
  justify-content: space-between
  padding: 10px 20px
  transition: all .5s
</style>
