import ufs from '@/assets/consts/ufs.json'
import InputModal from '@/components/shared/InputModal'
import SelectModal from '@/components/shared/SelectModal'
import CheckboxDark from '@/components/shared/CheckboxDark'
import FieldSelectMultiple from '@/components/shared/FieldSelectMultiple'

export default
[
  {
    key: 'email',
    initial: '',
    class: 'col-md-8',
    type: 'email',
    required: true,
    label: 'E-mail:',
    component: InputModal,
    placeholder: 'Insira seu email.'
  },
  {
    key: 'cpf',
    initial: '',
    class: 'col-md-4',
    type: 'text',
    label: 'CPF:',
    required: true,
    component: InputModal,
    mask: '###.###.###-##',
    placeholder: 'Insira seu CPF.'
  },
  {
    key: 'nome',
    initial: '',
    type: 'text',
    required: true,
    label: 'Nome:',
    class: 'col-md-6',
    component: InputModal,
    placeholder: 'Insira seu Nome.'
  },
  {
    key: 'sobrenome',
    initial: '',
    class: 'col-md-6',
    type: 'text',
    required: true,
    label: 'Sobrenome:',
    component: InputModal,
    placeholder: 'Insira seu Sobrenome.'
  },
  {
    type: 'text',
    required: true,
    mask: '##/##/####',
    key: 'dataNascimento',
    initial: '',
    class: 'col-md-3',
    component: InputModal,
    label: 'Data de Nascimento:',
    placeholder: 'Insira aqui a data de nascimento.'
  },
  {
    key: 'telefone',
    initial: '',
    class: 'col-md-3',
    required: true,
    type: 'text',
    component: InputModal,
    mask: '(##) #####-####',
    label: 'Celular:',
    placeholder: 'Insira aqui o seu telefone.'
  },
  {
    required: false,
    key: 'listaAreasInteresse',
    initial: [],
    class: 'col-md-6',
    component: FieldSelectMultiple,
    list: [],
    label: 'Áreas de interesse:',
    placeholder: 'Escolhas as áreas de seu interesse.'
  },
  {
    key: 'servidorPublico',
    initial: 'N',
    class: 'col-md-4',
    required: true,
    component: SelectModal,
    label: 'Servidor Público:',
    list: [{
      value: 'S',
      label: 'Sim'
    }, {
      value: 'N',
      label: 'Não'
    }]
  },
  {
    key: 'ocupacao',
    initial: '',
    class: 'col-md-4',
    required: true,
    type: 'text',
    component: InputModal,
    label: 'Profissão/Cargo:',
    placeholder: 'Informe aqui sua profissão.'
  },
  {
    type: 'text',
    required: true,
    class: 'col-md-4',
    component: InputModal,
    key: 'nomeEmpresaTrabalha',
    initial: '',
    placeholder: 'Informe aqui.',
    label: 'Empresa:',
    show: (form) => form.servidorPublico === 'N'
  },
  {
    type: 'text',
    required: true,
    class: 'col-md-4',
    component: InputModal,
    key: 'nomeOrgaoPublicoTrabalha',
    initial: '',
    placeholder: 'Informe aqui.',
    label: 'Órgão Público:',
    show: (form) => form.servidorPublico === 'S'
  },
  {
    key: 'cep',
    initial: '',
    class: 'col-md-3',
    required: true,
    type: 'text',
    component: InputModal,
    mask: '#####-###',
    label: 'CEP:',
    placeholder: 'Insira aqui o seu CEP.'
  },
  {
    key: 'uf',
    initial: '',
    list: ufs,
    label: 'Estado:',
    required: true,
    class: 'col-md-3',
    placeholder: 'Insira o estado.',
    component: SelectModal
  },
  {
    key: 'cidade',
    initial: '',
    required: true,
    label: 'Cidade:',
    class: 'col-md-6',
    placeholder: 'Insira a cidade.',
    component: SelectModal,
    conditional: (form) => ufs.find(uf => uf.value === form?.uf)?.cities || []
  },
  {
    key: 'bairro',
    initial: '',
    required: true,
    label: 'Bairro:',
    class: 'col-md-3',
    placeholder: 'Informe o bairro.',
    component: InputModal
  },
  {
    key: 'rua',
    initial: '',
    required: true,
    label: 'Rua:',
    class: 'col-md-4',
    placeholder: 'Informe a rua.',
    component: InputModal
  },
  {
    key: 'numero',
    initial: '',
    required: true,
    label: 'Número:',
    mask: '########',
    class: 'col-md-2',
    placeholder: 'Insira o número.',
    component: InputModal
  },
  {
    key: 'complemento',
    initial: '',
    required: false,
    label: 'Complemento:',
    class: 'col-md-3',
    placeholder: 'Insira o complemento.',
    component: InputModal
  },
  {
    key: 'receberNotificacao',
    initial: true,
    required: false,
    label: 'Quero receber no meu e-mail notificações sobre cursos das minhas áreas de interesse e interações de fórum:',
    class: 'col-md-8 mt-3',
    component: CheckboxDark
  }
  // {
  //   key: 'debitosParaOrgaoPublico',
  //   initial: false,
  //   required: false,
  //   label: 'Os boletos referentes as minhas inscrições vão ser emitidos em nome do órgão público que trabalho:',
  //   class: 'col-md-8 mt-3',
  //   component: CheckboxDark,
  //   show: (form) => form.servidorPublico === 'S'
  // }
]
